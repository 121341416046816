import React, { useState } from 'react';
import { useLabels, useFlags, useRequestData } from 'wsm-common-data';
import { hysteriFetch, wrappers } from 'hysterics';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import logger from 'ws-scripts/modules/logger';
import PropTypes from 'prop-types';
import Spinner from './Spinner';

const fetchData = hysteriFetch('api-data');

const WindowSticker = ({ vehicle, btnClasses }) => {
	const labels = useLabels();
	const flags = useFlags();
	const { locale } = useRequestData();

	const btnClassesStr = btnClasses.replace('hidden', '');

	const [isValid, setIsValid] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const vehicleObj = {
		make: vehicle.make,
		vin: vehicle.vin,
		type: vehicle.type,
		year: vehicle.year,
		flags,
		locale,
		accountId: vehicle.accountId,
		certified: vehicle.certified,
		extraFranchises: ''
	};

	const handleWindowSticker = async () => {
		setIsLoading(true);
		try {
			const response = await fetchData(
				'/api/widget/ws-inv-listing/getwindowstickerurl',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					[wrappers.retry.contextKey]: {
						retries: 1
					},
					[wrappers.timeout.contextKey]: {
						timeout: 500
					},
					body: JSON.stringify(vehicleObj)
				}
			);
			const responseData = await response.data;
			if (responseData) {
				setIsValid(true);
				window.open(responseData, '_blank').focus();
			} else {
				setIsValid(false);
			}
		} catch (e) {
			setIsValid(false);
			const errorString = JSON.stringify(e);
			setNewRelicCustomAttribute(
				'srpWindowStickerFetchError',
				errorString
			);
			logger.error(`Window sticker fetch Error: ${errorString}`);
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return (
			<button
				className={`${btnClassesStr} mb-3`}
				aria-disabled="true"
				type="button"
				disabled
			>
				<Spinner /> {labels.get('VIEW_WINDOW_STICKER')}
			</button>
		);
	}

	if (!isValid) {
		return (
			<button
				className={`${btnClassesStr} mb-3`}
				aria-disabled="true"
				type="button"
				disabled
			>
				{labels.get('WINDOW_STICKER_NOT_AVAILABLE')}
			</button>
		);
	}

	return (
		<button
			className={` ${btnClassesStr} mb-3`}
			onClick={handleWindowSticker}
			type="button"
		>
			{labels.get('VIEW_WINDOW_STICKER')}
		</button>
	);
};

export default WindowSticker;

WindowSticker.propTypes = {
	btnClasses: PropTypes.string,
	vehicle: PropTypes.shape({
		accountId: PropTypes.string,
		condition: PropTypes.string,
		make: PropTypes.string,
		vin: PropTypes.string,
		type: PropTypes.string,
		year: PropTypes.string,
		locale: PropTypes.string,
		certified: PropTypes.bool,
		flags: PropTypes.shape({})
	}).isRequired
};
